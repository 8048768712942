.App {
  text-align: center;
}

img {
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.tdn{
  text-decoration: none;
}

#root {
  background-color: #151515;
  min-height: 100vh;
  font-family: 'ultraRegular';
}

.MuiButtonBase-root.m {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  font-family: 'ultraBold';
  background-color: transparent;
}

.MuiButtonBase-root.m:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  color: rgba(255,255,255);
  /* background-color: transparent; */
  /* border-bottom: 2px solid; */
  background: linear-gradient(to bottom right, #14FD41, #D8D8D8);
  -webkit-background-clip: text;
  color: transparent;
  border-radius: 0;
}

.MuiButtonBase-root.m:hover {
  background-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
}
.swiper-slide-active,.swiper-slide-duplicate-active{
  transform: scale(1);
}

